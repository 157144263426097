body {
  margin: 0;
  font-family: 'DotGothic16', sans-serif;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: black;
  font-size: large;

  color: white;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 600px) {
  .opening-image-container,
  .setting-image-container {
    width: 90dvw;
    height: 20dvh;
    margin: auto;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .opening-image-container,
  .setting-image-container {
    width: 80dvw;
    height: 30dvh;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .opening-image-container,
  .setting-image-container {
    width: 70dvw;
    height: 40dvh;
    margin: auto;
  }
}
