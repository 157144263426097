.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.form-div:hover {
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.831);
}

.set-id-button {
  animation: heartbeat 1s alternate infinite;
  box-shadow: 0px;
  cursor: pointer;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
    box-shadow: 0px;
  }

  100% {
    transform: scale(1.1);
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.831);
  }
}
