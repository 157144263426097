.opening-warnings {
  margin-top: 30dvh;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .opening-warnings {
    margin-top: 20dvh;
  }
}

.alert-msg {
  text-align: center;
  margin: auto;
  padding-right: 1px;
  padding-left: 1px;
}

.icons-container {
  display: flex;
  justify-content: center;
  gap: 3px;
}

.icons-container {
  display: flex;
  justify-content: center;
  gap: 3px;
}

.opening-image-container {
  margin-top: 1%;
  overflow: hidden;
  position: relative;
  animation: fadeIn 10s;
}

.image-background {
  height: 100%;
  width: 300%;
  background-repeat: repeat-x;
  background-size: contain;
  position: relative;
  animation: Slide-Img 10s linear infinite;
}

@keyframes Slide-Img {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-60dvw);
  }
}

.game-title {
  opacity: 1;
  position: absolute;
  animation: fadeIn 5s;
  z-index: 2;
  margin: 13% 25% 0 42%;
  font-size: 1.5em;
  font-weight: 900;
  text-shadow: 3px 3px black;
}

.menu-hidden {
  opacity: 1;
  animation: Hide-Menu 4s ease 1s forwards;
}

.menu-hidden2 {
  opacity: 1;
  animation: Hide-Menu 4s ease 3s forwards;
}

@keyframes Hide-Menu {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.main-menu-container-initial {
  opacity: 0;
  transform: translateX(200px);
  animation: Show-Display 3s ease 0s forwards, Translate 2s ease 0s forwards;
}

.regular-menu-container-normal {
  opacity: 1;
}

.final-text-container {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  margin-top: -15dvh;
}

.deleted-file {
  opacity: 1;
  animation: Delete-File 1s ease 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.332);
  box-shadow: 0px 0px 10px rgb(238, 69, 69);
  cursor: not-allowed;
}

.deleted-file button {
  pointer-events: none;
  cursor: not-allowed;
}

.deleted-file::before {
  content: 'DELETED';
  color: red;
}

@keyframes Delete-File {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

.loading-file {
  transform: scale(1);
  animation: Loading-File 0.8s alternate infinite;
  box-shadow: 0px 0px 20px white;
}

.loading-file::before {
  content: 'LOADING...';
  color: purple;
  font-weight: 600;
}

.loading-file button {
  pointer-events: none;
  cursor: not-allowed;
}

@keyframes Loading-File {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.block-files div {
  cursor: not-allowed;
}

.block-itens .form-div {
  cursor: not-allowed;
  background-color: rgba(34, 34, 34, 0.351);
}

.block-itens button,
.block-itens input,
.block-files button {
  pointer-events: none;
}

.hide-nav {
  opacity: 0;
}

.show-nav {
  opacity: 0;
  animation: Show-Display 3s ease 0s forwards, Translate 2s ease 0s forwards;
}

@keyframes Translate {
  from {
    transform: translateX(200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes Show-Display {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.game-button {
  color: white;
  background-color: transparent;
  font-size: large;
  border: transparent;
}

.game-button:hover,
.back-button:hover,
.log-out-button:hover,
.set-id-button:hover {
  /* border: 0.1px outset #d0d0d087; */
  box-shadow: -0px -0px 5px 3px rgba(142, 143, 169, 0.378);
}

.game-button:hover::before,
.back-button:hover::before,
.log-out-button:hover {
  content: '> ';
  /* font-size: small; */
}

.log-out-button {
  border: 0.5 solid white !important;
  color: yellow;
  background-color: transparent;
}

.menu-button,
.disabled-button {
  display: block;
  margin: 10px;
}

.disabled-button {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.496);
  border: transparent;
}

.menu-button:hover {
  transform: scale(1.1);
  padding: 2px;
}

.menuOptions {
  animation: fadeIn 5s;
  display: flex;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.saved-file-container,
.game-slot-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.game-slot-container div {
  border-top: 1px solid rgba(255, 255, 255, 0.629);
  border-left: 1px solid rgba(255, 255, 255, 0.629);
}
.load-file-container {
  border: 1px solid white;
}

.skip-button-container {
  text-align: end;
  /* margin-top: 30dvh; */
  position: absolute;
  bottom: 0;
  margin-bottom: 3dvh;
}

.Typewriter__cursor {
  display: none;
}
