/* .prologue-container {
  max-width: 100%;
  max-height: 80dvh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
} */

.prologue-main {
  padding-bottom: 0;
}

.prologue-text {
  margin-top: -5dvh;
}

.prologue-container {
  display: flex;
  justify-content: center;
  transform: scale(0.7);
  margin-top: -25dvh;
}

.animation-container {
  margin-top: 80vh;
  width: 15dvw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: div-margin 3s 2s forwards;
}

@keyframes div-margin {
  0% {
    margin-top: 80vh;
  }
  100% {
    margin-top: 20vh;
  }
}

.portal-animation-finale {
  width: 100%;
  background-color: rgb(121, 209, 209);
  bottom: 0;
  position: relative;
  animation: portal 3s 2s forwards;
  box-shadow: 0 0 20px rgb(121, 209, 209);
}

.portal-animation {
  width: 100%;
  background-color: rgb(223, 39, 39);
  bottom: 0;
  position: relative;
  animation: portal 3s 2s forwards;
  box-shadow: 0 0 20px rgb(209, 121, 121);
}

@keyframes portal {
  0% {
    height: 0vh;
  }
  100% {
    height: 60vh;
  }
}

.shadow-container {
  opacity: 0;
  transition: 1s ease;
  animation: shadow 3s 2s forwards;
}

.div-shadow {
  opacity: 1;
  display: flex;
  width: 15dvw;
  height: auto;
  min-height: 10dvh;
  background-color: rgb(224, 78, 78);
}

.div-shadow-finale {
  opacity: 1;
  display: flex;
  width: 15dvw;
  height: auto;
  min-height: 10dvh;
  background-color: rgb(76, 134, 134);
}

@keyframes shadow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#shadow-left {
  position: relative;
  transform: skew(-45deg);
  margin-right: 6dvw;
}

#shadow-right {
  transform: skew(45deg);
  position: absolute;
  margin-left: 6dvw;
}
